import React,{useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';



export default function DemoRequest({modal, setModal,demoModal, setDemoModal}) {
    const exitModal = () =>{
        setDemoModal("none")
    
      }

    const [fullName,setFullName]= useState('')
    const [firmName,setFirmName]= useState('')
    const [email, setEmail]= useState('')
    const [state,setState]= useState('') 
    const [phone, setPhone]= useState('')
    const [country,setCountry]= useState('')
    const [checkedDemo, setCheckedDemo]=useState(false)

    const cookieModal= () =>{
    setModal("cookiepolicy")
    }

    const privacyModal= () =>{
    setModal("privacypolicy")
    }

    const termsModal= () =>{
    setModal("termsofuse")

    }

    const demoPopup =()=>{
        setModal("DemoDeckModal")
        setCheckedDemo(checkedDemo => false )
    }



    const changeChecker= (e:React.ChangeEvent<HTMLInputElement>) =>{
    if (e.target.checked){
        console.log("Checked")
    }else{
        console.log("unchecked")
    }
    setCheckedDemo(checkedDemo => !checkedDemo )


    }

    const calledBTN = ()=>{
    const validRegex=/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(validRegex)){
        const requestOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':' *'
        },
        body: JSON.stringify({ 
            "state":state,
            "country":country,
            "email":email,
            "full_name":fullName,
            "firm_name":firmName,
            "phone":phone,
            "demo":1
        })
        };
        if(checkedDemo===true){
        try{ 
        fetch('https://api.investhub.ventures/add/konzortia', requestOptions)
            .then((response)=>{
            if(response.status==402){
                toast("Email already in use")
                return
            }
            if(response.status==201){
                demoPopup()
                return
              }
            toast(response.status + ": " + response.statusText)
            }) 
            }catch(error){
            toast(error)
            }
        }else{
        toast("checkbox not checked")
        }
    }else{
    toast("Email invalid")
    return
    }
    }

    if(demoModal === "Demo"){
    return (
    < >
    <section className="konzortia-blue-modal flex justify-center flex-col md:flex-row items-center pb-5 z-30 fixed inset-0 outline-none focus:outline-none">
        <article className='bg-[#061228] h-50 px-2 md:px-10 py-10 z-50 border '>
            <p className='text-red-600 text-xl flex justify-end cursor-pointer' onClick={exitModal}>X</p>
            <h2 className='text-white text-center font-minionproregular text-2xl'>Request our Demo</h2>
            <div className='h-1 w-50 konzortia-gradient mb-2'></div>
            <article className='grid w-80  text-white grid-cols-2 gap-4'>
                <input value={fullName} onChange={(e)=> setFullName(e.target.value)} className='bg-input col-span-2' type="text" placeholder='Full Name' />
                <input value={firmName} onChange={(e)=> setFirmName(e.target.value)} className='bg-input col-span-2' type="text" placeholder='Firm Name'/>
                <input value={email} onChange={(e)=> setEmail(e.target.value)} className='bg-input' type="email" placeholder='Email'/>
                <input value={phone} onChange={(e)=> setPhone(e.target.value)} className='bg-input' type="text" placeholder='Phone Number'/>
                <CountryDropdown
                        classes= 'bg-input'
                        value={country}
                        onChange={(val) => setCountry(val)} />
                <RegionDropdown 
                        classes='bg-input'
                        country={country}
                        value={state}
                        onChange={(val) => setState(val)} />
                <label className='w-80 text-white text-xs'>
                
                <input className='col-span-2' type="checkbox" onChange={changeChecker}/>
                By signing up, you agree to our <span className="text-blue-400 cursor: pointer;" onClick={privacyModal}>privacy policy</span>, <span  className="text-blue-400 cursor: pointer;"  onClick={cookieModal}>cookie policy</span>, and <span className="text-blue-400 cursor: pointer;" onClick={termsModal}>terms of use</span>. You also agree to receive Investhub’s emails and messages about our products and services.              </label>
            </article>
            <button onClick={calledBTN} className='Konzortia-CTA-BTN text-white px-8 mx-20 my-2 pr-10'>Submit</button>
            <article className='text-white flex justify-center items-center pr-5 pt-5' >
                <span> (833) 930 2161  Contact us</span>
            </article>
        </article>
    </section>
    <ToastContainer />

    </>
    )}
    if(demoModal === !"demo"){
        return(
            <>
            </>
        )
    }
  }
