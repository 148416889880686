import React from 'react'

export default function Schedule() {
  return (
    <>
        <section className='kon-lighterblue-bg flex justify-center text-center items-center flex-col py-10 gap-5'>
            <h4 className='p-3 text-2xl'> 
                For More Information Schedule a Meeting with our Director of Investor Relations
            </h4>
            <a className="" href="https://calendly.com/angelo-quiceno-1">
              <button className='bg-white klb-text px-8 h-10'>Schedule</button>
            </a>
        </section>
    </>
  )
}
