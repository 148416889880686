import React from 'react'

export default function Footer({setModal}) {
  const privacyModal= () =>{
    setModal("privacypolicy")
  }
  
  const termsModal= () =>{
    setModal("termsofuse")

  }
  return (
    <>
        <section className='bg-white flex justify-center content-center w-fullscreen h-auto center-div flex-col mx-2 md:mx-20 md:flex-row 2xl:justify-between'>
            <article className='konzortia-borderline my-3 pl-3 md:h-72 '>
                <h3 className='klb-text mb-2'>Konzortia Capital Inc</h3>
                <p className='mb-5'>99 Wall Street Suite 5895 
                  New York NY 10005.</p>
                <p className='mb-5'> <span className='klb-text mb-2'>Toll Free</span> +1 (833) 9302161</p>
                <span className='klb-text mb-'>Email</span>
                <p> capital@konzortia.capital</p>

                <p className='mt-8'>Konzortia Capital Inc. Holding registration no. 3897083
                Special Purpose Vehicle Registered in Delaware, USA.
                Konzortia Capital SPV 001 LLC, Incorporation no. 3884967</p>
            </article>    
            <article className='konzortia-borderline my-2 md:h-60  px-3'>
              <h4  className='klb-text'>Investors Area</h4>
              <a href="https://konzortia.capital/invest-in-konzortia-capital/">Invest in Konzortia Capital Press Releases</a>
              <h4  className='klb-text mt-5'>Register as a Visionary Investor</h4>
              
              <a href="http://20.9.50.6/Investment-Deals-Curated-Deal-Flow-Projects">  <button className='border-2 border-black rounded-full text-sm px-1 mt-1'>Sign Up</button></a>
            </article>  
            <article className='konzortia-borderline my-3 md:h-28  px-3'>
              <h4 className='klb-text'>Explore</h4>
              <a href="https://konzortia.capital/our-aproach/">Who We Are & Our Approach</a>
            </article>  
            <article className='konzortia-borderline my-3 md:h-28  px-3'>
                <h4 className='klb-text w-32'>Our Platform</h4>
                <a href="https://www.investhub.ventures/">InvestHub</a>
            </article>  
            <article className='konzortia-borderline my-3 md:h-40  px-3'>
              <h4  className='klb-text mb-3 w-40'>Connect with us</h4>
              <a className='flex flex-inline mr-3 mb-2' href="https://www.linkedin.com/company/konzortiacapital/mycompany/"><img className='mr-2 w-5' src="linkedin-footer.png" alt="social"></img> Linkedin</a>
              <a className='flex flex-inline mr-3 mb-2' href="https://twitter.com/konzortia_c/"><img className='mr-2 w-5' src="twitter-footer.png"  alt="social"></img> Twitter</a>
              <a className='flex flex-inline mr-3 mb-2' href="https://www.facebook.com/konzortia/"><img className='mr-2 w-5' src={require=('facebookfooter.png')}  alt="social f"></img> Facebook</a>
              <a className='flex flex-inline mr-3 mb-2' href="https://www.instagram.com/konzortia_capital/"><img className='mr-2 w-5' src={require=('instagramfooter.png')} alt="social I"></img> Instagram</a>
            </article>  
        </section>    
        <section>
        <p className='flex justify-end mx-5 text-xs pb-5'><span className="text-blue-400 cursor-pointer" onClick={privacyModal}> Privacy Policy  &nbsp;  </span> <span  className="text-blue-400 cursor-pointer" onClick={termsModal}>Terms of Service  &nbsp; </span> ©2022 Konzortia Capital Inc. All Rights Reserved.</p>
        </section>
    </>
  )
}
