import React from 'react'

export default function EarlyTraction() {
  return (
    <>
        <section className='grid grid-cols-1 flex justify-center md:grid-cols-2 items-center md:items-center h-auto py-10 gap-10 '>
            <article className="mx-auto">
                <img className='w-80' src="early-traction.png" alt=""  />
                <a href="https://konzortia.capital"><img className='w-80' src="etkc.png" alt="" /></a>
                <a href="https://www.investhub.ventures/"><img  className='w-80' src="etih.png" alt="" /></a>
                <a href="https://konzortia.capital/konzortia-capital-acquisition-paraforge/"></a><img className='w-80' src="etpf.png" alt="" />
            </article>
            <article className='flex justify-between flex-col py-5 text-sm pb-8 px-10 mx-auto'>
                <article >
                    <p><img className='w-5 h-5 inline' src="checkmark.png" alt="s"/>Strong Management Team</p>
                </article>
                <article>
                    <p><img className='w-5 h-5 inline'  src="checkmark.png" alt="s"/>Pre-Seed & Seed Capital Achievement</p>
                </article>
                <article>
                    <p><img className='w-5 h-5 inline'  src="checkmark.png" alt="s"/>Acquisition of M&A of Paraforge Valued at $5.3MM</p>
                </article>
                <article>
                    <p><img className='w-5 h-5 inline'  src="checkmark.png" alt="s"/>Last 2 years of operations Paraforge generated $5.2MM+ in revenues</p>
                </article>
            <article>
                <p><img className='w-5 h-5 inline' src="checkmark.png" alt="s"/>Gross Profit Margin of 77.48%</p>
            </article>
            </article>
        </section>
    </>
  )
}
