import React from 'react'

export default function KeyMarkets() {
  return (
    <>
    <section className='bg-white p-10'>
        <article className='flex justify-center items-center gap-5 grid grid-cols-2 px-auto 2xl:w-1/6 2xl:mx-auto md:gap-28 md:grid-cols-5 md:ml-8 2xl:pr-20 pl-10'>
            <article className='md:w-20 w-[40px]'>
                <img src="risk.png" alt=""  />
                <p>Reduce Risk</p>
            </article>
            <article className='md:w-20 w-[40px]'>
                <img src="bar-chart.png" alt=""  />
                <p>Increase Efficiency</p>
            </article>
            <article className='md:w-20 w-[40px] '>
                <img src="on-time.png" alt=""  />
                <p>Save Time</p>
            </article>
            <article className='md:w-20 w-[40px] '>
                <img src="assessment.png" alt=""  />
                <p>Reasonable Valuation</p>
            </article>
            <article className='md:w-20 w-[40px]'>
                <img src="growth.png" alt=""  />
                <p>High Growth</p>
            </article>
        </article>
        <article className='p-5 flex 2xl:mx-auto 2xl:mt-5 2xl:w-2/3'>
            <p className='text-xs flex justify-center items-center'>
                Konzortia Capital’s solutions reduce the risk for investors by providing significant vetting thus eliminating much of the uncertainty of non-operational early-stage companies, combined with the assurance of market fit, but still within a very modest valuation giving investors the possibility of experiencing high growth. Our technology also provides access to capital to established and early-stage companies in capital markets. We are a global service provider. In addition to servicing traditional capital markets, we have the distinct capability of reaching underserved and growing markets, such as: US Midwest; LATAM; and Emerging Markets.
            </p>
        </article>
        <section>
            <h3 className='flex justify-center text-center text-xl py-10 font-minionprobold text-2xl'> KE <span className='klb-text'>Y MARKETS</span></h3>
            <article className='flex justify-center text-center'>  
               <img src="keym.png" alt="Key Markets"  />
            </article>
            <h3 className='konzortia-blue-text text-xl flex justify-center text-center py-10 text-2x5'>Providing Curated & Undervalued Projects to Fund!</h3>
        </section>
    </section>
    </>
  )
}
