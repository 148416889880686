import React,{useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ReactGA from 'react-ga';



export default function RequestPitch({setModal}) {

  const cookieModal= () =>{
    setModal("cookiepolicy")
    }

  const privacyModal= () =>{
    setModal("privacypolicy")
  }

  const termsModal= () =>{
    setModal("termsofuse")

  }


  const [fullName,setFullName]= useState('')
  const [firmName,setFirmName]= useState('')
  const [email, setEmail]= useState('')
  const [state,setState]= useState('') 
  const [phone, setPhone]= useState('')
  const [country,setCountry]= useState('')
  const [checked, setChecked]=useState(false)


  const changeChecker= (e:React.ChangeEvent<HTMLInputElement>) =>{
    if (e.target.checked){
      console.log("Checked")
    }else{
      console.log("unchecked")
    }
    setChecked(checked => !checked )


  }

  const pitchPopup =()=>{
    setModal("PitchDeckModal")
    // setChecked(checked => false )
}

  const calledBTN = ()=>{
    const validRegex=/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(validRegex)){
      const requestOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':' *'
      },
        body: JSON.stringify({ 
          "state":state,
          "country":country,
          "email":email,
          "full_name":fullName,
          "firm_name":firmName,
          "phone":phone,
          "pitchdeck":1
        })
      };
      if(checked===true){
        try{ 
        fetch('https://api.investhub.ventures/add/konzortia', requestOptions)
            .then((response)=>{
            if(response.status==402){
              toast("Email already in use")
              return
            }
            if(response.status==201){
              pitchPopup()
              return
            }
            toast(response.status + ": " + response.statusText)
            }) 
          }catch(error){
            toast(error)
          }
        }else{
        toast("checkbox not checked")
      }
  }else{
    toast("Email invalid")
    return
  }
}

  const useAnalyticsEventTracker = (category="submit") => {
    const eventTracker = (action = "submit", label = "fintechinvestment-v1") => {
      ReactGA.event({category, action, label});
    }
    return eventTracker;
  }

  const gaEventTracker = useAnalyticsEventTracker('Submit');
  return (
    < >
    <section className="konzortia-blue flex justify-center flex-col md:flex-row items-center pb-5 w-50">
        <article className='h-50 w-50'>
          <a href="https://konzortia.capital/">
            <img className='md:relative md:top-20 md:left-10 z-20 md:w-80 flex justify-center items-center pb-5' src="Konzortia-Logo.png" alt=""  />
           </a> 
           <article className="text-white text-2xl md:relative md:top-20 md:left-20 z-20 flex justify-center items-center flex-col">
              <h2 className='w-60 text-center font-minionproregular'>Pre-Series A Round</h2>
              <p className='border w-60 text-center text-xl px-2 font-bold '>Raising $3.8MM</p>
            </article>
            <img className="z-10 md:w-96"src="Konzortia-cityscape-vector.png" alt=""  />
        </article>
        <article className='h-50 w-80 mx-36 pt-20'>
            <h2 className='text-white text-center font-minionproregular text-2xl'>Request our Pitch Deck</h2>
            <div className='h-1 w-50 konzortia-gradient mb-2'></div>
            <article className='grid w-80  text-white grid-cols-2 gap-4'>
              <input value={fullName} onChange={(e)=> setFullName(e.target.value)} className='bg-input col-span-2' type="text" placeholder='Full Name' />
              <input value={firmName} onChange={(e)=> setFirmName(e.target.value)} className='bg-input col-span-2' type="text" placeholder='Firm Name'/>
              <input value={email} onChange={(e)=> setEmail(e.target.value)} className='bg-input' type="email" placeholder='Email'/>
              <input value={phone} onChange={(e)=> setPhone(e.target.value)} className='bg-input' type="text" placeholder='Phone Number'/>
              <CountryDropdown
                        classes= 'bg-input'
                        value={country}
                        onChange={(val) => setCountry(val)} />
              <RegionDropdown 
                        classes='bg-input'
                        country={country}
                        value={state}
                        onChange={(val) => setState(val)} />
                <label className='w-80 text-white text-xs'>
                
                  <input className='col-span-2' type="checkbox" onChange={changeChecker}/>
                  By signing up, you agree to our <span className="text-blue-400 cursor: pointer;" onClick={privacyModal}>privacy policy</span>, <span  className="text-blue-400 cursor: pointer;"  onClick={cookieModal}>cookie policy</span>, and <span className="text-blue-400 cursor: pointer;" onClick={termsModal}>terms of use</span>. You also agree to receive Investhub’s emails and messages about our products and services.
                </label>
            </article>
            <button onClick={calledBTN} className='Konzortia-CTA-BTN text-white px-8 mx-20 my-2 pr-10'>Submit</button>
            <article className='text-white flex justify-center items-center pr-5 pt-5' >
                <span> (833) 930 2161  Contact us</span>
            </article>
        </article>
    </section>
    <ToastContainer />
    
    </>
  )
  }
