import React from 'react'

export default function DiscoverKonzortiaCapital({setDemoModal}) {
  const makeDemo = () =>{
    setDemoModal("Demo")
  }
  return (
    <>
      <section className='bg-white w-auto flex justify-center items-center flex-col pb-20'>
        <h2 className='py-10 font-minionprobold text-2xl text-center'>DISCOVER KONZORTIA CAPITAL</h2>
        <article className='grid grid-cols-2 gap-5 md:flex md:gap-20  '>
          <p className=' w-60 col-span-2 md:col-span-0'>
          Konzortia’s InvestHub is the Only Deal Sourcing Platform in Existence with AI  and Machine Learning to Match Investors Criteria.
          </p>
          <article className='w-28 '>
            <img src="teamwork.png" alt="s"   className='object-scale-down h-10 w-10'/>
            <p>Matches to 800,000 Investors Globally.</p>
          </article>
          <article className='w-28'>
            <img src="big-data.png" alt="s"   className='object-scale-down h-10 w-10'/>
            <p>Hundreds of Thousands of Data Points and Growing!</p>
          </article>
          <article className='w-28'>
            <img src="cash-flow.png" alt="s"   className='object-scale-down h-10 w-10'/>
            <p>Vetted & Curated Deal Flows.</p>
          </article>
          <article className='w-28'>
            <img src="pie-chart.png" alt="s"   className='object-scale-down h-10 w-10'/>
            <p>Access to Projects and Capital in Underserved Markets.</p>
          </article>
        </article>        
      </section> 
      <section className='konzortia-light-blue flex justify-center items-center text-center flex-col py-5'>
          <h2 className='py-10 text-white text-2xl'>Request a Demo of our Paraforge AI/ML Matching</h2>
          <button className='bg-white klb-text px-5 'onClick={makeDemo} >Submit</button>
      </section>   
    </>
  )
}
