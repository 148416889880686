import React from 'react'

export default function MarketSolutions() {
  return (
    <>
    <section className='kon-lighterblue-bg h-auto pb-20 flex justify-center text-center flex-col'>
        <h2 className='flex justify-center text-center text-lx py-10 text-white font-bodonifl text-2xl'>Providing Private Market Solutions</h2>
        <article className='flex justify-center mx-auto  flex-col md:flex-row gap-2'>
            <article className='w-60 auto'>
                <img src="semicolon.png" alt=""  />
                <p className='px-5 italic'>“There is currently more than $1 trillion of capital in the collective private equity piggybank that is considered dry powder.”
                </p>
                <p className='mx-5 my-2 italic'>Jerry Kadlic, Founding Partner of Evolution Capital Partners</p>
            </article>
            <article className='w-60 auto'>
                <img src="semicolon.png" alt=""  />
                <p className='mx-5 italic'>“Competition for deals is fierce, and value creation can be tough given the broader macro environment. Private capital firms are increasingly looking for an advantage and often struggle to get the information and insights they need to make the best decisions.”</p>
                <p className='mx-5 my-2 italic'>Shannon Dolan, SVP & Head of Private Markets, FIS</p>
            </article>
            <article className='w-60 auto '>
                <img  src="semicolon.png" alt=""  />
                <p className='mx-5 italic'>“70% (of Private Equity Leaders surveyed) said they’ll use more data during the diligence process, and 68% said they’ll use more tech and tools, versus people.”</p>
                <p className=' mx-5 my-2 italic'><a href="https://www.westmonroe.com/perspectives/point-of-view/digitizing-due-diligence-pe" >West Monroe Survey of 100 PE Leaders</a></p>
            </article>
        </article>
    </section>

    </>
  )
}
