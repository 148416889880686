import React from 'react'

export default function () {
  return (
    <>
    <section className='bg-about px-10 py-10' >
        <div className='h-5 my-2 flex items-start 2xl:justify-center 2xl:flex-center'>
          <h2 className='bg-black text-white w-15 h-5 px-5 mb-5 font-minionprobold'>ABOUT US</h2>
        </div>
        <article className='flex justify-between items-center flex-col'>
          <p className="2xl:w-2/3"> <a href="https://konzortia.capital/"> Konzortia Capital </a>is a FinTech consortium providing VC/PE effortless Deal Sourcing for Investors and funding opportunities for companies. As part of our value proposition, we promise to Source - Match – Exit. The recent <a href="https://www.digitaljournal.com/pr/konzortia-capital-acquires-paraforge-for-5-3-million">acquisition of Paraforge</a> and its AI / ML technology allows Konzortia Capital and its Intellectual Property (IP) to provide differentiated services and greater operational efficiency for the needs of the Private Market sector. Our in-house Distributed Ledger System will ensure transactions are transparent, secure, and clear in real-time. <a href="https://www.investhub.ventures/">InvestHub</a> is the first mainstream platform to provide transactions for funding and exit strategies.</p>
          <article className='flex justify-between items-center flex-col my-5  gap-3 md:flex-row'>
            <a href="https://konzortia.capital"><img className="w-48 md:w-80" src="kc.png" alt="konzortia"/></a>
            <a href="https://www.investhub.ventures/"><img className="w-48 md:w-80" src="ih.png" alt="investhub"/></a>
            <a href="https://konzortia.capital/konzortia-capital-acquisition-paraforge/"> <img className="w-48 md:w-80" src="pf.png" alt="gif"  /></a>
          </article>
        </article>
    </section>
    
    </>
  )
}
