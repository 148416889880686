import './App.css';
import './index.css'
import {React, useEffect,useState} from 'react';
import LinkedInTag from 'react-linkedin-insight';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import DemoRequestModal from './Components/Modals/DemoRequest';
import Modals from './Components/Modals/Modals';

import RequestPitch from './Components/RequestPitch';
import DiscoverKonzortiaCapital from './Components/DiscoverKonzortiaCapital';
import About from './Components/About';
import EarlyTraction from './Components/EarlyTraction';
import MarketSolutions from './Components/MarketSolutions';
import SocialMedia from './Components/SocialMedia';
import Schedule from './Components/Schedule';
import Footer from './Components/Footer';
import KeyMarkets from './Components/KeyMarkets';
import PitchDeckModal from './Components/Modals/PitchDeckModal';
import DemoPDModal from './Components/Modals/DemoPDModal';

function App() {
  
const GApageView = (page) => {   
  ReactGA.pageview(page);   
}
  useEffect(() => { GApageView("landing"); }, [])

  useEffect(() => {
    if(process.env.REACT_APP_GOOGLE_PIXEL_ID !== undefined){
      ReactGA.initialize(String(process.env.REACT_APP_GOOGLE_PIXEL_ID));
      ReactGA.pageview('fintechinvestment-v1');
    }
  }, [process.env.REACT_APP_GOOGLE_PIXEL_ID])

  useEffect(() => {
    if(process.env.REACT_APP_META_PIXEL_ID !== undefined){
      ReactPixel.init(String(process.env.REACT_APP_META_PIXEL_ID));
      ReactPixel.pageView()
    }
  }, [process.env.REACT_APP_META_PIXEL_ID])

  useEffect(() => {
    if(process.env.REACT_APP_LINKEDIN_PIXEL_ID !== undefined){
      LinkedInTag.init(String(process.env.REACT_APP_LINKEDIN_PIXEL_ID));
    }
  }, [process.env.REACT_APP_LINKEDIN_PIXEL_ID])

  const [modal, setModal]=useState("None")
  const [demoModal, setDemoModal]= useState("None")
  return (
    <>
      <RequestPitch setModal={setModal}/>
      <DiscoverKonzortiaCapital setDemoModal={setDemoModal}/>
      <About/>
      <EarlyTraction/>
      <MarketSolutions />
      <KeyMarkets/>
      <SocialMedia/>
      <Schedule/>
      <Footer setModal={setModal}/>
      <DemoRequestModal modal={modal} setModal={setModal} demoModal={demoModal} setDemoModal={setDemoModal}/>
      <Modals modal={modal} setModal={setModal}/>
      <PitchDeckModal modal={modal} setModal={setModal}/>
      <DemoPDModal modal={modal} setModal={setModal} demoModal={demoModal} setDemoModal={setDemoModal} />
    </>
    
  )
}

export default App;
