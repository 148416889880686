import React from 'react'

export default function CookiePolicy({modal, setModal}) {
  const exitModal = () =>{
    setModal("none")


  }
  // const cookieModal = ()=>{
  //   setModal("cookiepolicy")
  // }
  const privacyModal = ()=>{
    setModal("privacypolicy")


  }
  const termsModal = ()=>{
    setModal("termsofuse")


  }
  if(modal === "cookiepolicy"){
    return(
    <>
    <section className='konzortia-blue-modal flex justify-center flex-col md:flex-row  items-center pb-5 z-30 fixed inset-0 outline-none focus:outline-none'>
      <article className='h-[85vh] p-2 bg-white overflow-x-hidden overflow-y-hidden border border-black '> 
      <p className='text-red-600 text-lg flex relative left-[95%] pb-2 cursor-pointer' onClick={exitModal}>X</p>
      <article className='h-[65vh] md:w-96 w-64  bg-slate-200 px-2 md:px-10 py-10 z-50 overflow-y-auto overflow-x-hidden'>
      
      <h3 className='font-bold mb-5 text-2xl'>Cookie Policy</h3>
      <p className=' w-30 mb-5'> This document represent InvestHub’s cookies policy (the “Cookie Policy")</p>
      <p className=' mb-5'>Please read this Cookie Policy carefully before using https://fintechinvestment.konzortia.capital/ (the “Website”) and InvestHub Platform (the “Platform") operated by InvestHub.</p>
      <h3 className='flex  font-bold mb-5'>What are cookies?</h3>
      <p className=' mb-5'>Cookies are simple text files that are stored on your devices’ (computer, mobile device, etc.) by a website’s server. Each cookie is unique to your web browser. Cookies are a common tool used online to learn about and respond to user(s) website activities. Most websites, web applications, and mobile applications use cookies to improve each user’s experience and deliver relevant ads for user(s).</p>
      <h3 className='flex  font-bold mb-5'>How does cookies work?</h3>
      <p className=' mb-5'>Whenever you as user visit a website, the website sends a cookie to the device you are using to access the website. Your device automatically stores the cookies in a file so when you revisit particular website, the website will respond in a more personalized way by remembering your preferences, providing faster page load times, and so forth.</p>
      <h3 className='flex  font-bold mb-5'>What types of cookies do we use?</h3>
      <h4 className='font-bold mb-5 underline'>Necessary cookies</h4>
      <p className=' mb-5'>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account.</p>      
      <h4 className='font-bold mb-5 underline'>Functionality cookies</h4>
      <p className=' mb-5'>Functionality cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.</p>
      <h4 className='font-bold mb-5 underline'>Analytical cookies</h4>
      <p className=' mb-5'>These cookies enable us and third-party services (such as Facebook, Google, Youtube, etc.) to collect aggregated data for statistical  purposes on how our visitors use the website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the website.</p>
      <h3 className='flex  font-bold mb-5'>How we use cookies and tracking technologies?</h3>
      <p className=' mb-5'>During any visit or use of our services on the Website and/or Platform, the pages you view – along with cookies – are downloaded to your device, and those help InvestHub to understand the behavior of our visitors/users and provide you with a better, more personalized experience. Cookies also allow us to provide our services to you, by recognizing you when you access the Website/Platform and by making it easier for you to navigate through it and improve your experience with our services. </p>
      <h3 className='flex  font-bold mb-5'>Third-party cookies on our services</h3>
      <p className=' mb-5'>When you visit our Website/Platform you may notice content from other websites - for example, Facebook, Google, Youtube,- which we add from time to time to improve your experience. As a result, you may be sent cookies from these other website. Please note that InvestHub does not control these cookies and we suggest you check these third-party websites for more information about the cookies they use and how you can manage them.
      </p>
      <p className=' mb-5'> InvestHub also engages third parties (such as Google's DoubleClick), and others, to serve advertisements on our behalf across the Internet and to provide analytics about the use of our Website. We may also participate in online advertising networks and exchanges that display relevant advertisements to our Website visitors based on their interests as reflected in their browsing of the website and certain third party sites participating in the same networks and exchanges. These entities may use cookies, web beacons, pixels, embedded scripts, and other technologies to collect information automatically about your use of the website, such as your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on the Website and other websites and better understand your online activity.</p>
      <p className=' mb-5'>These and other third parties may also collect personal information about your online activities over time and across different websites when you use our Website. We may also work with third parties to serve ads to you as part of a customized campaign on other websites or platforms.</p>
      <h3 className='flex  font-bold mb-5'>How to disable cookies? </h3>
      <p className='px-2 mb-5'> Depending on your preference, you can disable the cookies on our Website/Platform except those that are necessary for performing our services.
        Please note that you as user, at any point, have the right to request that we do not process your personal information for any marketing purposes following the procedures outlined in our <span className='text-blue-400' onClick={privacyModal}>Privacy Policy</span>.
        To remove other cookies from your PC, you can use these guides:
        Internet Explorer: hitps:support.microsoft.com/en-us/kb/278835#/en-us/kb/278835
        Chrome: https:support.google.com/chrome/answer/95647?hl=en
        Safari: https:support.apple.com/kb/PH17191?locale=en GB
        Firefox: https:support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
      </p>
      <h3 className='flex  font-bold mb-5'>Contacting us</h3>
      <p className=' mb-5'>If you have any questions about this policy or our use of cookies, please contact us.</p>
      </article>
      <article className='flex justify-center items-center my-5'>
        <button onClick={exitModal} className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
          Close
        </button>
        <button onClick={exitModal} className='bg-orange font-noah-white active:bg-emerald-600  uppercase  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
           Okay
        </button>
        </article>
      </article>
        {/* <span onClick={exitModal}>hh</span> */}
      
    </section>
  </> )}
  if(modal === "privacypolicy"){
    return(
    <>
    <section className='konzortia-blue-modal flex justify-center flex-col md:flex-row  items-center pb-5 z-30 fixed inset-0 outline-none focus:outline-none'>
      <article className='h-[85vh] p-2 bg-white overflow-x-hidden overflow-y-hidden  border border-black rounded'> 
      <p className='text-red-600 text-lg flex cursor-pointer relative left-[95%] pb-2' onClick={exitModal}>X</p>
      <article className='h-[65vh] md:w-96 w-64  bg-slate-200 px-2 md:px-10 py-10 z-50 overflow-y-auto overflow-x-hidden'>
        <h3 className='flex font-bold mb-5 text-2xl'>Privacy Policy</h3>
        <p className=' mb-5'>
          The privacy of InvestHub users and their information is extremely important to InvestHub and we take this matter very seriously. Any InvestHub services are provided through InvestHub platform (the “Platform”).
        </p>
        <p className=' mb-5'>
          All user(s) of InvestHub’s services (the “User(s)) (services provided through the following website https:fintechinvestment.konzortia.capital/ the “Site”) are covered by this Privacy Policy (the “Privacy Policy”). InvestHub reserves the right to update and/or amend this Privacy Policy, in its sole discretion; therefore, please be advised to check Privacy Policy from time to time in case as such, InvestHub will release user information update (the “Release”) and any further engagement with InvestHub upon such Release shall mean that the user(s) have accepted the amended/updated Privacy Policy
        </p>
        <p className=' mb-5'>Persons under the age of 18 years are prohibited from using InvestHub and any associated services provided therein; persons under the age of 18 years are therefore not authorized to submit any and all private information to the system and InvestHub has no liability and/or responsibility for any and all such actions. </p>
        <p className=' mb-5'>Upon registering as a InvestHub user, certain contact information such as your name, email address, mailing address and other information may be collected. This serves as an example and intended to be a complete and extensive list of information that may be collected by InvestHub at registration.</p>
        <h3 className='flex  font-bold mb-5'>InvestHub</h3>
        <p className=' mb-5'>InvestHub is an online service platform that allows Users to set-up an investment criterion to match curated deals leverage on InvestHub´s artificial intelligence platform. Only users who are manually approved by InvestHub’s administration will be able to access the Site. </p>
        <h3 className='flex  font-bold mb-5'>System Emails</h3>
        <p className=' mb-5'>As part of the normal functions of the InvestHub Platform, we will send you email notices regarding your profile information on the Site. We will try to keep these emails limited to a minimum and only necessary.</p>
        <p className=' mb-5'>InvestHub will send you system generated emails, information regarding policies of the Platform and/or promotional materials (the “Communications”). These Communications may begin during the registration process and continue while your account remains active on the InvestHub platform. </p>
        <h3 className='flex  font-bold mb-5'>Internet</h3>
        <p className=' mb-5'>As part of our efforts to provide better services to InvestHub users, InvestHub collects certain information related to the Internet and/or your computer. For us to analyze trends and provide superior service to our users, InvestHub retrieves the web address (URL) of both the website that you visited directly before and directly after visiting the InvestHub site. We also receive information about your personal computer such as the unique IP address. InvestHub also follows a standard practice of using cookies and logs to analyze website usage; an extremely small files that are stored on your computer and help us to provide you with a better experience by automatically recalling your preferences each time you visit or use our Site. Cookies will not harm your computer in any way and can be deleted by you at any time whatsoever.</p>
        <h3 className='flex  font-bold mb-5'>Profile</h3>
        <p className=' mb-5'>Following registration, InvestHub users will be prompted to complete a profile. Current users can also make changes to their own user profile. A user profile may contain but is not limited to any information or statement of Users based on specific information requested by InvestHub in order to fit the investment criteria of investors or projects using InvestHub. You should use good judgment about the information that you post in your profile since this can be viewed by other User(s) on InvestHub. Your personal contact information CANNOT be viewed unless you release this information (you have full control over the privacy of this section in the user profile). Additionally, the information in your profile can be edited at your discretion at any time</p>
        <h3 className='flex  font-bold mb-5'>Account Preferences</h3>
        <p className=' mb-5'>You may make changes to your InvestHub account and any associated preferences at any time. To make changes, you must log onto InvestHub and make any edits through the My Account section. You may also request to delete your InvestHub account. To request a removal of your account, please contact us here.</p>
        <h3 className='flex  font-bold mb-5'>Abuse</h3>
        <p className=' mb-5'>By entering into our User Agreement, InvestHub Users agree to not abuse other Users' personal information. Abuse is defined as using any User’s personal information for any purpose other than those explicitly specified in the <span className='text-blue-400' onClick={termsModal}>Terms of Services </span>(the “Terms of Services) or not related to fulfilling delivery of a product or service explicitly specified in the Terms of Services of InvestHub.</p>
        <h3 className='flex  font-bold mb-5'>Personal Data</h3>
        <p className=' mb-5'>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for. Therefore:</p>
        <ol className=' mb-5 list-disc'>
          <li>Personal Data collected for purposes related to the performance of a contract between InvestHub, and the User shall be retained until such contract has been fully performed;
          </li>
          <li>
          Personal Data collected for the purposes of InvestHub legitimate interest shall be retained as long as needed to fulfill such purposes. 
          </li>
        </ol>
        <p className=' mb-5'>InvestHub may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, InvestHub may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</p>
        <p className=' mb-5'>Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after retention's period expiration.
        </p>
        <h3 className='flex  font-bold mb-5'>What are the purposes for which we use your personal information?</h3>
        <p className=' mb-5'>
        We may use your personal information to allow the Service's operational functioning and features (“business purposes”). In such cases, your personal information will be processed in a fashion necessary and proportionate to the business purpose for which it was collected and strictly within the limits of compatible operational purposes.
        </p>
        <p className=' mb-5'>
        We may also use your personal information for other reasons such as for commercial purposes, as well as for complying with the law and defending our rights before the competent authorities where our rights and interest are threatened, or we suffer actual damage.
        </p>
        <p className=' mb-5'>
        We will not use your personal information for different, unrelated, or incompatible purposes without notifying you
        </p>
        <h3 className='flex  font-bold mb-5'>Methods of processing</h3>
        <p className=' mb-5'>
          
        InvestHub takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to InvestHub, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Service (administrative, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT Companies, communications agencies) appointed, if necessary, as Data Processors by InvestHub.

        </p>
        <h3 className='flex  font-bold mb-5'>
        Questions; Requests</h3>
        <p className=' mb-5'> If you have a question about this Privacy Policy, please contact us at the following email or address:</p>
        <h3 className='flex  font-bold mb-5'> Management@konzortia.capital </h3>
        <p className=' mb-5'> Konzortia Capital INC.</p>
        <p className=' mb-5'> 99 Wall Street #5895 </p>
        <p className=' mb-5'> New Your, NY 10005</p>
        <p className=' mb-5'> Any requests to exercise User rights can be directed to InvestHub through the contact details provided above. These requests can be exercised free of charge and will be addressed by InvestHub as early as possible and always within one month.</p>
        <h3 className='flex  font-bold mb-5'>Your California privacy rights and how to exercise them</h3>
        <h3 className='flex  font-bold mb-5'> 
        The right to know and to portability</h3>
        <p className=' mb-5'> You have the right to request that we disclose to you:</p> 
        <ol className=' mb-5 list-disc'>
          <li> The categories and sources of personal information that we collect about you, the purposes for which we use your information, with whom we use your information, and with whom such information is shared;</li>
          <li> In case of personal information's sale or disclosure for business purposes, two separate lists where we disclose:</li>
          <ol>
            <li>For sale, the personal information categories purchased by each category of the recipient; and</li>
            <li> For disclosure for a business purpose, the personal information categories obtained by each category of receipt.</li>
          </ol>
        </ol>
        <p className=' mb-5'>The disclosure described above will be limited to the personal information collected or used over the past 12 months.</p> 
        <p className=' mb-5'> If we deliver our responses electronically, the information enclosed will be “portable”, i.e., delivered in an easily usable format to enable you to transmit the information to another entity without hindrance - provided that this is technically feasible.</p> 
        <h3 className='flex  font-bold mb-5'>The right to request the deletion of your personal information</h3>
        <p className=' mb-5'>
          You have the right to request that we delete any or all of your personal information, subject to exceptions set forth by the law (such as including but not limited to, where the information is used to identify and repair errors on this Service, to detect security incidents and protect against fraudulent or illegal activities, to exercise certain rights, etc.).
          </p> 
        <p className=' mb-5'> If no legal exception applies we will delete your personal information and direct any of our service providers to do so as a result of exercising your right.</p> 
        <h3 className='flex  font-bold mb-5'> How to exercise your rights</h3>
        <p className=' mb-5'> To exercise the rights described above, you need to submit your verifiable request by contacting us via the details provided in this document. For us to respond to your request, it is necessary that we know who you are. Therefore, you can only exercise the above rights by making a verifiable request which must:</p> 
        <ol className=' mb-5 list-disc'>
            <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative;</li>
            <li>Describe your request with sufficient detail that allows us to understand, evaluate, and respond to it properly.</li>
        </ol>        
        <p className=' mb-5'>If you are an adult, you can make a verifiable request on behalf of a minor under your parental authority.</p> 
        <p className=' mb-5'>You can submit a maximum number of 2 requests over a period of 12 months.
        </p> 
        <h3 className='flex  font-bold mb-5'>How and when we are expected to handle your request</h3>
        <p className=' mb-5'>
        We will confirm receipt of your verifiable request within 10 days and provide information about how we will process your request.
        </p> 
        <p className=' mb-5'>
        We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfill your request.
        Our disclosure(s) will cover the preceding 12 month period.
        </p> 
        <p className=' mb-5'>
          
        Should we deny your request, we will explain to you the reasons behind our denial.
        We do not charge a fee to process or respond to your verifiable request unless such request is manifestly unfounded or excessive. In such cases, we may charge a reasonable fee or refuse to act on the request. In either case, we will communicate our choice and explain the reasons behind it.

        </p> 
        <h3 className='flex  font-bold mb-5'>
        Information for Users residing in Brazil
        </h3>
        <p className=' mb-5'>
        This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the entity running this Service and, if the case may be, its parent, subsidiaries, and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).
        </p> 
        <p className=' mb-5'>
          
          The provisions contained in the section apply to all Users who reside in Brazil, according to the “Lei Geral de Proteção de Dados” Users are referred to below, simply as “you”, “your”, “yours”). For such Users, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.

        </p> 
        <p className=' mb-5'>
          This part of the document uses the term “personal information” as it is defined in the “Lei Geral de Proteção de Dados” LGPD.
        </p> 
        <h3 className='flex  font-bold mb-5'>
          
          The grounds on which we process your personal information

        </h3>
        <p className=' mb-5'>
        We can process your personal information solely if we have a legal basis for such processing. Legal bases are as follows:
        </p> 
        <ol className=' mb-5 list-disc'>
          <li>Your consent to the relevant processing activities;</li>
          <li>Compliance with a legal or regulatory obligation Controller) that lies with us;</li>
          <li> Carrying out of public policies provided in laws or regulations or based on contracts, agreements, and similar legal instruments;</li>
          <li>Carrying out studies conducted by research entities, preferably carried out on anonymized personal information;</li>
          <li>Carrying out of a contract and its preliminary procedures, in cases where you are a party to the said contract;</li>
          <li>Exercising of our rights in judicial, administrative, or arbitration procedures; 	-Protection of the life or physical safety of yourself or a third party;</li>
          <li>Health protection — in procedures carried out by health entities or professionals;</li>
          <li>Fulfil our legitimate interests, provided that your fundamental rights and liberties do not prevail over such interests; and</li>
          <li>Credit protection</li>
        </ol>
        <p className=' mb-5'>To find out more about the legal bases, you can contact us at any time using the contact details provided in this document.</p> 
        <h3 className='flex  font-bold mb-5'>Categories of personal information processed</h3>
        <p className=' mb-5'>
        To find out what categories of your personal information are processed, you can read the section titled “Detailed information on the processing of Personal Data” within this document.
        </p> 
        <h3 className='flex  font-bold mb-5'>
          
          Why we process your personal information
        </h3>
        <p className=' mb-5'>
          To find out why we process your personal information is processed, you can read the section titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.

        </p> 
        <h3 className='flex  font-bold mb-5'>
          Your Brazilian privacy rights
        </h3>
        <p className=' mb-5'>
          You have the right to:
        </p> 
        <ol className=' mb-5 list-disc'>
          <li>Obtain confirmation of the existence of processing activities on your personal information;</li>
          <li> Have incomplete, inaccurate, or outdated personal information rectified/corrected;</li>
          <li> Obtain the anonymization, blocking, or elimination of your unnecessary or excessive personal information or of information that is not being processed in compliance with the LGPD;</li>
          <li>Obtain information on the possibility to provide or deny your consent and the consequences thereof;</li>
          <li> Obtain information about the third parties with whom we share your personal information;</li>
          <li> Obtain, upon your express request, the portability of your personal information (except for anonymized information) to another service or product provider, provided that our commercial and industrial secrets are safeguarded;</li>
          <li> Obtain the deletion of your personal information being processed if the processing was based upon your consent, unless one or more exceptions are provided for in art. 16 of the LGPD apply;</li>
          <li> Revoke your consent at any time;</li>
          <li> Lodge a complaint related to your personal information with the ANPD (the National Data Protection Authority) or with consumer protection bodies;</li>
          <li> Oppose a processing activity in cases where the processing is not carried out in compliance with the provisions of the law;</li>
          <li> Request clear and adequate information regarding the criteria and procedures used for an automated decision; and</li>
          <li> Request the review of decisions made solely based on automated processing of your personal information, which affects your interests. These include decisions to define your personal, professional, consumer, and credit profile or aspects of your personality.</li>
        </ol>
        <p className=' mb-5'>
          You will never be discriminated against or otherwise, suffer any sort of detriment if you exercise your rights.
        </p>
        <h3 className='flex  font-bold mb-5'>
          How to file your request
        </h3>
        <p className=' mb-5'> You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document or via your legal representative.</p> 
        <h3 className='flex  font-bold mb-5'>How and when we will respond to your request</h3>
        <p className=' mb-5'> We will strive to respond to your requests promptly.</p>  
        <p className=' mb-5'> In any case, should it be impossible for us to do so, we will make sure to communicate to you the factual or legal reasons that prevent us from immediately, or otherwise ever, complying with your requests. In cases where we are not processing your personal information, we will indicate to you the physical or legal person to whom you should address your requests if we are in the position to do so.</p>  
        <p className=' mb-5'> In the event that you file an access or personal information processing confirmation request, please make sure that you specify whether you would like your personal information to be delivered in electronic or printed form.</p>  
        <p className=' mb-5'> You will also need to let us know whether you want us to answer your request immediately, in which case we will answer in a simplified fashion, or if you need a complete disclosure instead.</p>  
        <p className=' mb-5'> In the latter case, we will respond within 15 days from the time of your request, providing you with all the information on the origin of your personal information, confirmation on whether or not records exist, any criteria used for the processing and the purposes of the processing, while safeguarding our commercial and industrial secrets.</p> 
        <p className=' mb-5'> 
        In the event that you file a rectification, deletion, anonymization, or personal information blocking request, we will make sure to immediately communicate your request to other parties with whom we have shared your personal information in order to enable such third parties to also comply with your request – except in cases where such communication is proven impossible or involves a disproportionate effort on our side.
        </p> 
        <h3 className='flex  font-bold mb-5'> 
        Transfer of personal information outside of Brazil permitted by the law
        </h3>
        <p className=' mb-5'> 
        We are allowed to transfer your personal information outside of the Brazilian territory in the following cases:
        </p>  
        <ol className=' mb-5 list-disc'>
          <li>When the transfer is necessary for international legal cooperation between public intelligence, investigation and prosecution bodies, according to the legal means provided by the international law;</li>
          <li>When the transfer is necessary to protect your life or physical security or those of a third party;</li>
          <li> When the transfer is authorized by the ANPD;</li>
          <li> When the transfer reslits from a commitment undertaken in an international cooperation agreement;</li>
          <li> When the transfer is necessary for the execution of a public policy or legal attribution of public service;</li>
          <li> When the transfer is necessary for compliance with a legal or regliatory obligation, the carrying out of a contract or preliminary procedures related to a contract, or the regliar exercise of rights in judicial, administrative, or arbitration procedures.</li>
        </ol>
        <h3 className='flex  font-bold mb-5'>
        Information for Users residing in EU
        </h3>
        <p className=' mb-5'>
        This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the entity running this Service and, if the case may be, its parent, subsidiaries, and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).
        </p>  
        <p className=' mb-5'>
          The provisions contained in the section apply to all Users who reside within the EU Users are referred to below, simply as “you”, “your”, “yours”). For such Users, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.
        </p>  
        <p className=' mb-5'>
          This part of the document uses the term “data subject” and “controller” as it is defined in the General Data Protection Regliation GDPR.
        </p>  
        <h3 className='flex  font-bold mb-5'>
          The grounds on which we process your personal information
        </h3>
        <p className=' mb-5'>
          We can process your personal information solely if we have a legal basis for such processing. Legal bases are as follows:
        </p>  
        <ol className=' mb-5 list-disc'>
          <li>Consent from data subject to the processing of Personal Data for one or more specific purposes;</li>
          <li> Processing necessary for the performance of a contract to which the data subject is a party or in order to take steps at the request of the data subject prior to entering into a contract;</li>
          <li> Processing is necessary for compliance with a legal obligation to which the controller is subject;</li>
          <li> Processing is necessary in order to protect the vital interests of the data subject or another natural person;</li>
          <li> Processing is necessary for the performance of a task carried out in the public interest or the exercise of official authority vested in the controller;</li>
          <li> Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require personal data protection in particular where the data subject is a child.</li>
        </ol>
        <h3 className='flex  font-bold mb-5'> Categories of personal information processed</h3>
        <p className=' mb-5'>    
          To find out what categories of your personal information is processed, you can read the section titled “Detailed information on the processing of Personal Data” within this document.
        </p> 
        <h3 className='flex  font-bold mb-5'> Why we process your personal information</h3>
        <p className=' mb-5'> 
          To find out why we process your personal information are processed, you can read the section titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.
        </p> 
        <h3 className='flex  font-bold mb-5'> Your GDPR privacy rights</h3>
        <p className=' mb-5'> You have the right to: </p> 
        <ol className=' mb-5 list-disc'>
          <li>Right to rectification;</li>
          <li> 
            Right of erasure (“right to be forgotten”) Right of restriction of processing;
          </li>
          <li>
          Notification obligation regarding rectification or erasure of personal data or restriction of processing;
          </li>
          <li> 
          Right to data portability;
          </li>
          <li>
          Right to object and automated individual decision-making Right to object;
          </li>
          <li>
          Automated individual decision-making, including profiling Right to withdraw data processing consent.
          </li>
        </ol>
        <p className=' mb-5'> Each of these rights and the terms used to describe them has the same meaning as defined in the General Data Protection Regulation GDPR.</p> 
        <h3 className='flex  font-bold mb-5'> How to file your request</h3>
        <p className=' mb-5'> You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document or via your legal representative.</p> 
        <h3 className='flex  font-bold mb-5'>How and when we will respond to your request</h3>
        <p className=' mb-5'>
        We will strive to respond to your requests promptly
        </p> 
        <h3 className='flex  font-bold mb-5'> Contact Information</h3>
        <p className=' mb-5'>If you have any questions or would like to report any violations of these Terms of Service or other policies set forth by InvestHub please contact the following:
        Attn:<span onClick={termsModal}>Terms of Service</span> 
        </p> 
        <p className=' mb-5'>  Management@konzortia.capital</p> 
        <p className=' mb-5'> Konzortia Capital INC.
        99 Wall Street #5895 
        New York, NY 10005.
        </p>
        </article>
        <article className='flex justify-center items-center my-5'>
        <button onClick={exitModal} className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
          Close
        </button>
        <button onClick={exitModal} className='bg-orange font-noah-white active:bg-emerald-600  uppercase  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
           Okay
        </button>
      </article>
      </article>
   </section>
    </>
  )}
  if(modal === "termsofuse"){
    return(
    <>
      <section className='konzortia-blue-modal flex justify-center flex-col md:flex-row  items-center pb-5 z-30 fixed inset-0 outline-none focus:outline-none'>
      <article className='h-[85vh] p-2 bg-white overflow-x-hidden overflow-y-hidden border border-black '> 
      <p className='text-red-600 text-lg flex cursor-pointer relative left-[95%] pb-2' onClick={exitModal}>X</p>
      <article className='h-[65vh] md:w-96 w-64 bg-slate-200 px-2 md:px-10 py-10 z-50 overflow-y-auto overflow-x-hidden'>
          <h3 className='flex font-bold mb-5 md:text-2xl text-lg'>INVESTHUB TERMS OF SERVICES AGREEMENT (“TOS”)</h3>
          <p className=' mb-5'>
          This Invest Hub Services Agreement (this “Agreement”) is between Konzortia Capital Inc. (“InvestHub”) and the individual or entity concluded this Agreement (“You”) or (Your). This Agreement sets forth the terms and conditions that govern orders placed by You for Services under this Agreement (Services herein refers as the “Services”).
          </p>
          <h3 className='flex  font-bold mb-5'> DEFINITIONS</h3>
          <p className=' mb-5'> The terms listed below will have the following meaning when used in this TOS:</p>
          <ol className=' mb-5 list-disc'>
            <li><b>User/s:</b> Means a person or persons who utilizes a computer or network service </li>
            <li> Services Period: The period from the effective date and ending on the expiration date or its earlier termination date of the service relationship.</li>
            <li>Your order: Your payment  to InvestHub for the sale of specific products or services to be delivered in the future.</li>
            <li>Content: The information contained within communication media. </li>
            <li>Application/s:  Any request by any person entering information for accessing  InvestHub´s services. </li>
            <li>Service Specifications: information or other provisioning and management processes applicable to any of Your  Services.</li>
            <li>Personal Data: Any information relating to an identified or identifiable individual; an identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identification number or one or more factors.</li>
            <li>Third Party Content: All software, data, text, images, audio, video, photographs, and other content and material, in any forma, that are obtained or derived form a third party sources outside InvestHub that You may access through, within, or in conjunction with Your use of the Services. </li>
            <li>Data Processing Agreement: An agreement that regulates any personal data processing conducted for InvestHub for his business purposes. </li>
          </ol>
          <h3 className='font-bold mb-5'>TERMS OF SERVICES</h3>
          <p className=' mb-5'> These TOS describe the terms upon which you may access and use of InvestHub Software (the “Software”). By using the Software, you affirm that you have read and accepted all of these terms and conditions , which my be updated from time to time. </p>
          <p className=' mb-5'> Your continued use of the Software after changes to these TOS have been published constitutes your binding acceptance of the updated TOS.  If at any time the TOS are no longer acceptable to you, you should immediately cease all use of the Software. If you are an individual acting on behalf of a corporation or other legal entity, you represent that you have authority to agree to these TOS on behalf of such legal entity. </p>
          <p className=' mb-5'> If you do not agree to these Terms of Use, do not have the authority to agree to these Terms of Use, or withdraw your consent to the Terms of Use, then you may not access or use the Platform or any materials, documentation, information, or software published or otherwise made available on the Platform (collectively the “Content”).</p>
          <h3 className='font-bold mb-5'>USE OF SODTWARE</h3>
          <p className=' mb-5'> Any “Software” that is made available to you is the copyrighted work of InvestHub and/or one or more third parties. Your use of Software is governed by the terms of this TOS and/or applicable license which accompanies, are included with, or referenced in the Software (the "License"), and you agree to abide by applicable License(s). These TOS shall not be deemed to replace or otherwise amend any rights or obligations which may exist pursuant to separate license agreement (if applicable).</p>
          <h3 className='font-bold mb-5'>TERM OF AGREEMENT </h3>
          <p className=' mb-5'> This Agreement is valid for the order which this Agreement accompanies. This Agreement may also be referenced for any purchase that increases the quantity of the original Services ordered (e.g., additional Users), for any Services options offered by InvestHub for the original Services ordered, and for any renewal or Auto Renewal of the Services Period of the original order. </p>
          <h3 className='font-bold mb-5'>RIGHTS GRANTED</h3>
          <p className=' mb-5'> For the duration of the Services Period and subject to Your payment obligations, and except as otherwise set forth in this Agreement or Your order, You have the non-exclusive, non-assignable, royalty free, worldwide limited right to access and use the Services that You ordered, including anything developed by InvestHub and delivered to You as part of the Services, solely for Your internal business operations and subject to the terms of this Agreement and Your order, including the Service Specifications. You may allow your users to use the Services for this purpose and You are responsible for your users’ compliance with this Agreement and the order.</p>
          <p className=' mb-5'> You do not acquire under this Agreement any right or license to use the Services,  in excess of the scope and/or duration of the Services stated in Your order. Upon the end of the Services ordered, Your right to access and use the Services will terminate. </p>
          <p className=' mb-5'> To enable InvestHub to provide You and Your Users with the Services, You grant InvestHub the right to use, process, and transmit, in accordance with this Agreement and Your order, Your Content and Your Applications for the duration of the Services Period plus any additional post-termination period during which InvestHub provides You with access to retrieve an export file of You Content and Your Applications. If Your Applications include third party programs, You acknowledge that InvestHub may allow providers of those third party programs to access the Services , including Your Content and Your Applications, as required for the interoperation of such third party programs with the Services. InvestHub will not be responsible for any use, disclosure, modification or deletion of Your Content or Your Applications resulting from any such access by third party program providers or for the interoperability of such third-party programs with the Services. </p>
          <h3 className='font-bold mb-5'>OWNERSHIP AND RESTRICTIONS </h3>
          <p className=' mb-5'> 
            You retain any and all ownership and intellectual property rights, interests, and/or entitlements in and to Your Content and Your Applications. InvestHub or its licensors retain any and all ownership and intellectual property rights, interests, and/or entitlements to the Services, including any programs, and derivative works thereofand to anything developed or delivered by or on behalf of InvestHub under this Agreement (the “Deliverables”). 
          </p>
          <p className=' mb-5'> You may not, cause or permit others to: </p>
          <p className=' mb-5'> a) remove or modify any program markings or any notice of InvestHub’s or its licensors’ proprietary rights; </p>
          <p className=' mb-5'> b) make the programs or materials resulting from the Services (excluding Your Content and Your Applications) available in any manner or form to any third party for use in the third party’s business operations (unless such access is expressly permitted for the specific Services You have acquired); </p>
          <p className=' mb-5'> c) modify, make derivative works of, disassemble, decompile, or reverse engineer any part of the Services (the foregoing prohibition includes but is not limited to review of data structures or similar materials produced by programs), or access or use the Services and Software in order to build or support, and/or assist a third party in building or supporting, products or Services competitive to InvestHub; </p>
          <p className=' mb-5'> d) perform or disclose any benchmark or performance tests of the Services, without InvestHub’s prior written consent; </p>
          <p className=' mb-5'> e) perform or disclose any of the following security testing of the Services Environment or associated infrastructure without InvestHub’s prior written consent: network discovery, port and service identification, vulnerability scanning, password cracking, remote access testing, or penetration testing; and </p>
          <p className=' mb-5'>f) license, sell, rent, lease, transfer, assign, distribute, display, host, outsource, disclose, permit timesharing or service bureau use, or otherwise commercially exploit or make the Services, Services Environments or materials available, to any third party, other than as expressly permitted under the terms of the applicable order. </p>
          <p className=' mb-5'> The rights granted to You under this Agreement are also conditioned on the following:</p>
          <p className=' mb-5'> a) except as expressly provided herein or in Your order, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means; and </p>
          <p className=' mb-5'> b) You make every reasonable effort to prevent unauthorized third parties from accessing the Services with the same degree of care as those were at yours ownership.SERVICE SPECIFICATIONS </p>
          <p className=' mb-5'>The Services are subject to and governed by Service Specifications applicable to Your order. Service Specifications may define provisioning and management processes applicable to the Services. You acknowledge that use of the Services in a manner not consistent with the Service Specifications may adversely affect Services performance and/or may result in additional fees. If the Services permit You to exceed the ordered quantity, then You are responsible for promptly purchasing additional quantity to account for Your excess usage. </p>
          <p className=' mb-5'>
          InvestHub may make changes or updates to the Services (such as infrastructure, security, technical configurations, application features, etc.) during the Services Period, including to reflect changes in technology, industry practices, patterns of system use, and availability of Third-Party Content. The Service Specifications are subject to change at InvestHub’s sole discretion; however, InvestHub’s changes to the Service Specifications will not result in a material reduction in the level of performance or availability of the applicable Services provided to You for the duration of the Services Period. 
          </p>
          <h3 className='font-bold mb-5'> USE OF THE SERVICES </h3>
          <p className=' mb-5'>You are responsible for identifying and authenticating all Users, for approving access by such Users to the Services, for controlling against unauthorized access by Users, and for maintaining the confidentiality of information. By federating or otherwise associating Your and Your Users’ usernames, passwords, and accounts with InvestHub. InvestHub is not responsible for any harm, damage, omission, and/or expense caused by Your Users, including individuals who were not authorized to have access to the Services but who were able to gain access because usernames, passwords, or accounts were not terminated on a timely basis in Your local identity management infrastructure or your local computers. You are responsible for all activities that occur under Your and Your Users’ usernames, passwords, or accounts or as a result of Your or Your Users’ access to the Services, and agree to notify InvestHub immediately of any unauthorized use and thus hold harmless from any and all claims and/or actions related to such. </p>
          <p className=' mb-5'>
          You agree not to use or permit use of the Services, including by uploading, emailing, posting, publishing, or otherwise transmitting any material, including Your Content, Your Applications and any Third Party Content (if applicable), for any purpose that may (a) menace and/or harass any person or cause damage or injury to any person or property, (b) involve the publication of any material that is false, defamatory, harassing, or obscene, (c) violate privacy rights or promote bigotry, racism, hatred, or harm, (d) constitute unsolicited bulk e-mail, “junk mail”, “spam” or chain letters; (e) constitute an infringement of intellectual property or any other proprietary rights, or (f) otherwise violate applicable laws, ordinances, or regulations. In addition to any other rights, interests, and/or entitlements afforded to InvestHub under this Agreement, InvestHub reserves the right, but has no obligation, to take remedial action if any material violates the foregoing restrictions, including the removal or disablement of access to such material. InvestHub shall have no liability to You in the event that InvestHub takes such action. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and ownership of all of Your Content and Your Applications as well as for any actions and/or claims in relation to or out of Your Content raised by any third person. You agree to defend, hold harmless, and indemnify InvestHub against any and all claims, actions, damages, and/or expenses (including reasonable attorney’s fees) arising out of a violation of Your obligations under this section. 
          </p>
          <p className=' mb-5'>
          You are required to accept all patches, bug fixes, updates, maintenance, and service packs (collectively, “Patches”) necessary for the proper function and security of the Services. Except for emergency or security related maintenance activities, InvestHub will coordinate with You the scheduling of application of Patches, where possible, based on InvestHub’s next available standard maintenance window.
          </p>
          <h3 className='font-bold mb-5'>SERVICES PERIOD; END OF SERVICES </h3>
          <p className=' mb-5'> Services provided under this Agreement shall be provided for the Services Period defined in Your order, unless earlier suspended or terminated in accordance with this Agreement or the order. Upon the end of the Services, You no longer have rights to access or use the Services. </p>
          <p className=' mb-5'> InvestHub may temporarily suspend Your password, account, and access to or use of the Services if You or Your Users violate any provision within the ‘Rights Granted’, ‘Ownership and Restrictions’, ‘Fees and Taxes’, ‘Use of the Services’, or ‘Export’ sections of this Agreement, or if in InvestHub’s reasonable judgment, at sole discretion and without any obligation to make any reimbursements toward You whatsoever, the Services or any component thereof are about to suffer a significant threat to security and/or functionality. InvestHub will provide advance notice to You of any such suspension based on the nature of the circumstances giving rise to the suspension. InvestHub will use reasonable efforts to re-establish the affected Services promptly after InvestHub determines, in its reasonable discretion, that the situation giving rise to the suspension has been cured; however, during any suspension period, InvestHub will make available to You Your Content and Your Applications as existing in the Services Environment on the date of suspension. InvestHub may terminate the Services under an order if any of the foregoing causes of suspension is not cured within 30 days after InvestHub’s initial notice thereof.</p>
          <p className=' mb-5'>If either of Parties breaches a material term of this Agreement and fails to correct the breach within thirty (30) days of written specification of the breach, then the breaching party is in default and the non-breaching party may terminate the order under which the breach occurred, effective immediately. If InvestHub terminates the order,as specified in the preceding sentence, You must pay within thirty (30) days all amounts that have accrued prior to such termination, as well as all sums remaining unpaid for the Services ordered under this Agreement plus related taxes and expenses. Except for nonpayment of fees, the nonbreaching party may agree, in its sole discretion, to extend for another  thirty (30) day period as  long as the breaching party continues reasonable efforts to cure the breach. You agree that if You are in default under this Agreement, You may not use those Services ordered. </p>
          <h3 className='font-bold mb-5'>NONDISCLOSURE </h3>
          <p className=' mb-5'> By virtue of this Agreement and during the course of its exection, the Parties may or have access to information that is confidential to one another (“Confidential Information”). Parties hereto agree to disclose only information that is required for the performance of obligations under this Agreement. Confidential information shall be limited to the terms and pricing under this Agreement, Your Content and Your Applications residing in the Services Environment, and all information clearly identified as confidential at the time of disclosure. </p>
          <p className=' mb-5'> A Party’s Confidential Information shall not include information that: (a) is or becomes generally available to the public other than as a result of a disclosure by a party in breach of this Agreement; (b) was within a party’s possession prior to its being furnished to the other party by or on behalf of a party pursuant hereto; (c)  becomes available to a party on a non-confidential basis from a source other than the other party, provided that such source is not known by a party to be bound by an obligation of confidentiality with respect to such information; or (d) is independently developed by or for a party without use of the Confidential Information.</p>
          <p className=' mb-5'>Partiesagree not to disclose each other’s Confidential Information to any third party other than as set forth in this Agreement for a period of three (3) years from the date of the disclosing party’s disclosure of the Confidential Information to the receiving party; however, InvestHub will hold Your Confidential Information that resides within the Services Environment in confidence. We each may disclose Confidential Information only to those employees, agents, or subcontractors who have need to know and/or are required to protect it against unauthorized disclosure in a manner no less protective than required under this Agreement. </p>
          <p className=' mb-5'> InvestHub will protect the confidentiality of Your Content or Your Applications residing in the Services Environment in accordance with the InvestHub security practices defined as part of the Service Specifications applicable to Your order. In addition, Your Personal Data will be treated in accordance with the terms of Section DATA PROTECTION below. </p>
          <p className=' mb-5'>Nothing shall prevent either party from disclosing the terms or pricing under this Agreement or orders placed under this Agreement in any legal proceeding arising from or in connection with this Agreement or from disclosing the Confidential Information to a governmental entity as required by law. </p>
          <h3 className='font-bold mb-5'>DATA PROTECTION</h3>
          <p className=' mb-5'>In performing the Services, InvestHub will comply with the InvestHub Services Privacy Policy, which is available at www.xxxxx.com/dataprivacypolicy  and incorporated herein by reference. </p>
          <p className=' mb-5'>The InvestHub Services Privacy Policy is subject to change at InvestHub’s sole discretion; however, InvestHub policy changes will not result in a material reduction in the level of protection provided for Your Personal Data provided as part of Your Content during the Services Period of Your order. </p>
          <p className=' mb-5'>
          InvestHub’s Data Processing Agreement for (the “Data Processing Agreement”), which is available at http:www.ihxxxx.com/dataprocessingagreement and incorporated herein by reference, describes the Parties’ respective roles for the processing and control of Personal Data that You provide to InvestHub as part of the Services. InvestHub will act as a data processor, and will act on Your instruction concerning the treatment of Your Personal Data residing in the Services Environment, as specified in this Agreement, the Data Processing Agreement and the applicable order. You agree to provide any notices and obtain any consents related to Your use of the Services and/or InvestHub’s provision of the Services, including those related to the collection, use, processing, transfer, and/or disclosure of Personal Data. 

          </p>
          <p className=' mb-5'>
          The Service Specifications applicable to Your order define the administrative, physical, technical, and other safeguards applied to Your Content residing in the Services Environment, and describe other aspects of system management applicable to the Services. You are responsible for any and all security vulnerabilities, and the consequences, implications, damages, and/or costs of such vulnerabilities, arising from Your Content and Your Applications, including any viruses, Trojan horses, worms, or other programming routines contained in Your Content or Your Applications that could limit, compromise,or harm the functionality of a computer system.
          </p>
          <p className="  mb-5">
            WARRANTIES, DISCLAIMERS, AND EXCLUSIVE REMEDIES
          </p>
          <p className="  mb-5">
            InvestHub warrants that it will perform Professional
            Services in a professional manner in accordance with the
            Service Specifications and this TOS. If the Services
            provided to You were not performed as warranted, You must
            promptly provide written notice to InvestHub, withing sixty
            (60) days from starting the usage of the Services, by
            describing the deficiency in the Services.
          </p>
          <p className="  mb-5">
            INVESTHUB DOES NOT GUARANTEE THAT (A) THE SERVICES WILL BE
            PERFORMED ERROR-FREE OR UNINTERRUPTED, OR THAT INVESTHUB
            WILL CORRECT ALL SERVICES ERRORS, (B) THE SERVICES WILL
            OPERATE IN COMBINATION WITH YOUR CONTENT OR YOUR
            APPLICATIONS, OR WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEMS,
            OR DATA NOT PROVIDED BY INVESTHUB, AND (C) THE SERVICES WILL
            MEET YOUR REQUIREMENTS, SPECIFICATIONS OR EXPECTATIONS. YOU
            ACKNOWLEDGE THAT INVESTHUB DOES NOT CONTROL THE TRANSFER OF
            DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET,
            AND THAT THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS,
            AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH
            COMMUNICATIONS FACILITIES. INVESTHUB IS NOT RESPONSIBLE FOR
            ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE AND/OR COST
            (INCLUDING LOST PROFITS AND REVENUES) RESULTING FROM SUCH
            PROBLEMS. INVESTHUB IS NOT RESPONSIBLE FOR ANY ISSUES
            RELATED TO THE PERFORMANCE, OPERATION OR SECURITY OF THE
            SERVICES THAT ARISE FROM YOUR CONTENT, OR YOUR APPLICATIONS
            OR THIRD-PARTY CONTENT. INVESTHUB DOES NOT MAKE ANY
            REPRESENTATION OR WARRANTY REGARDING THE RELIABILITY,
            ACCURACY, COMPLETENESS, CORRECTNESS, OR USEFULNESS OF
            THIRD-PARTY CONTENT, AND DISCLAIMS ALL LIABILITIES ARISING
            FROM OR RELATED TO THIRD PARTY CONTENT. SERVICES ARE
            PROVIDED “AS IS”. FOR ANY BREACH OF THE SERVICES WARRANTY,
            YOUR EXCLUSIVE REMEDY AND INVESTHUB’S ENTIRE LIABILITY SHALL
            ONLY BE THE CORRECTION OF THE DEFICIENT SERVICES THAT CAUSED
            THE BREACH OF WARRANTY, OR, IF INVESTHUB CANNOT
            SUBSTANTIALLY CORRECT THE DEFICIENCY IN A COMMERCIALLY
            REASONABLE MANNER FOR INVESTHUB, YOU MAY END THE DEFICIENT
            SERVICES AND INVESTHUB WILL REFUND TO YOU ONLY THE PORTION
            OF THE FEES FOR THE TERMINATED SERVICES THAT YOU PRE-PAID TO
            INVESTHUB FOR THE PERIOD FOLLOWING THE EFFECTIVE DATE OF
            TERMINATION.
          </p>
          <p className="  mb-5">
            TO THE EXTENT NOT PROHIBITED BY LAW, THESE WARRANTIES ARE
            EXCLUSIVE AND THERE ARE NO OTHER,EXPRESS OR IMPLIED,
            WARRANTIES AND/OR CONDITIONS INCLUDING FOR SOFTWARE,
            HARDWARE, SYSTEMS, NETWORKS OR ENVIRONMENTS, FOR
            MERCHANTABILITY, SATISFACTORY QUALITY AND FITNESS FOR A
            PARTICULAR PURPOSE.
          </p>
          <p className="  mb-5">
            LIMITATION OF LIABILITY
          </p>
          <p className="  mb-5">
            NEITHER PARTY SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF
            REVENUE OR PROFITS (EXCLUDING FEES UNDER THIS AGREEMENT),
            DATA, OR DATA USE. INVESTHUB’S MAXIMUM LIABILITY FOR ALL
            CLAIMS, ACTIONS, DAMAGES AND/OR COSTS ARISING OUT OF OR
            RELATED TO THIS AGREEMENT OR YOUR ORDER, WHETHER IN CONTRACT
            OR TORT, OR OTHERWISE, SHALL IN NO EVENT EXCEED, IN THE
            AGGREGATE, THE TOTAL AMOUNTS ACTUALLY PAID TO INVESTHUB FOR
            THE SERVICES UNDER THE ORDER THAT IS THE SUBJECT OF THE
            CLAIM IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING
            THE EVENT GIVING RISE TO SUCH CLAIM LESS ANY REFUNDS OR
            CREDITS RECEIVED BY YOU FROM INVESTHUB UNDER SUCH ORDER.
          </p>
          <p className="  mb-5">
            THIRD PARTY WEB SITES, CONTENT, PRODUCTS, AND SERVICES
          </p>
          <p className="  mb-5">
            The Services may enable You to link to, transmit Your
            Content to, or otherwise access, other Web sites, content,
            products, services, and information of third parties. By
            accepting these TOS, you acknowledge that InvestHub does not
            control and is not responsible for such Web sites or any
            such content, products, services, and information accessible
            from or provided through the Services, and You bear any and
            all risks and/or costs associated with access to and use of
            such Web sites and third-party content, products, services,
            and information.
          </p>
          <p className="  mb-5">
            Any Third Party Content made accessible by InvestHub in or
            through the Services Environment is provided on an “as-is”
            and “as available” basis without any warranty of any kind,
            whatsoever. Third Party Content may be indecent, offensive,
            inaccurate, infringing, or otherwise objectionable or
            unlawful, and You acknowledge that InvestHub is not
            responsible for and under no obligation to control, monitor,
            or correct Third Party Content and You will hold InvestHub
            harmless; however, InvestHub reserves the right to take
            remedial action if any such content violates applicable
            restrictions under Section USE OF THE SERVICE of this
            Agreement, including the removal of, or disablement of
            access to, such content.
          </p>
          <p className="  mb-5">
            You acknowledge that: (i) the nature, type, quality and
            availability of Third Party Content may change at any time
            during the Services Period, and (ii) features of the
            Services that interoperate with third parties such as
            Facebook™, YouTube™ and Twitter™, etc. (each, a “Third Party
            Service”), depend on the continuing availability of such
            third parties’ respective application programming interfaces
            (APIs) for use with the Services. InvestHub may update,
            change, or modify the Services under this Agreement as a
            result of a change in, or unavailability of, such Third
            Party Content, Third Party Services and/or APIs. If any
            third party ceases to make its Third Party Content and/or
            APIs available on reasonable terms for the Services, as
            determined by InvestHub, in its sole discretion, InvestHub
            may cease providing access to the affected Third Party
            Content or Third Party Services without any liability to you
            whatsoever. Any changes to Third Party Content, Third Party
            Services, or APIs, including their availability or
            unavailability, during the Services Period does not affect
            Your obligations under this Agreement or the applicable
            order, and You will not be entitled to any refund, credit,
            or other compensation due to any such changes.
          </p>
          <p className="  mb-5">
            SERVICES TOOLS AND ANCILLARY PROGRAMS
          </p>
          <p className="  mb-5">
            InvestHub may use tools, scripts, software, and utilities
            (collectively, the “Tools”) to monitor and administer the
            Services and to help resolve You service requests. The Tools
            will not collect or store any of Your Content or Your
            Applications residing in the Services Environment, except as
            necessary to provide the Services or troubleshoot service
            requests or other problems in the Services. Information
            collected by the Tools (excluding Your Content and Your
            Applications) may also be used to assist in managing
            InvestHub’s product and service portfolio, to help InvestHub
            address deficiencies in its product and service offerings,
            and for license and Services management.
          </p>
          <p className="  mb-5">
            SERVICE ANALYSES
          </p>
          <p className="  mb-5">
            InvestHub may (i) compile statistical and other information
            related to the performance, operation, and use of the
            Services, and (ii) use data from the Services Environment in
            aggregated form for security and operations management, to
            create statistical analyses, and for research and
            development purposes (clauses i and ii are collectively
            referred to as “Service Analyses”). InvestHub may make
            Service Analyses publicly available; however, Service
            Analyses will not incorporate Your Content or Confidential
            Information in a form that could serve to identify You or
            any individual connected to You, and Service Analyses do not
            constitute Personal Data. InvestHub retains all intellectual
            property rights in Service Analyses.
          </p>
          <p className="  mb-5">
            EXPORT
          </p>
          <p className="  mb-5">
            Export laws and regulations of the United States and any
            other relevant local export laws and regulations apply to
            the Services. You agree that such export laws govern Your
            use of the Services (including technical data) and any
            Services deliverables provided under this Agreement, and You
            agree to comply with all such export laws and regulations
            (including “deemed export” and “deemed re-export”
            regulations). You agree that no data, information, software
            programs, and/or materials resulting from Services (or
            direct product thereof) will be exported, directly or
            indirectly, in violation of these laws, or will be used for
            any purpose prohibited by these laws.
          </p>
          <p className="  mb-5">
            FORCE MAJEURE
          </p>
          <p className="  mb-5">
            Neither Party shall be responsible for failure or delay of
            performance of the obligations under this Agreement, if such
            failure or delay is caused by an act of war, hostility, or
            sabotage, act of God, pandemic, electrical, internet, or
            telecommunication outage that is not caused by the obligated
            party, government restrictions (including the denial or
            cancelation of any export, import or other license), or
            other event(s) outside the reasonable control of the
            obligated party. Parties agree both to use reasonable
            efforts to mitigate the effect of a force majeure event. If
            such event continues for more than thirty (30) days, either
            Party may cancel unperformed Services and affected orders
            upon written notice. This Section does not excuse either
            party’s obligation to take reasonable steps to follow its
            normal disaster recovery procedures or Your obligation to
            pay for the Services.
          </p>
          <p className="  mb-5">
            GOVERNING LAW AND JURISDICTION
          </p>
          <p className="  mb-5">
            This Agreement is governed by the substantive and procedural
            laws of the State of Delaware, without regard to any
            conflict of laws provisions and You and InvestHub agree to
            submit to the exclusive jurisdiction of, and venue in, the
            courts of the State in any dispute arising out of or
            relating to this Agreement. The Uniform Computer Information
            Transactions Act does not apply to this Agreement or to
            orders placed under it.
          </p>
          <p className="  mb-5">
            NOTICE
          </p>
          <p className="  mb-5">
            Any notice required under this Agreement shall be provided
            to the other party in writing. If You have a dispute with
            InvestHub or if You wish to provide a notice under the
            Indemnification Section of this Agreement, or if You become
            subject to insolvency or other similar legal proceedings,
            You will promptly send written notice to:
            management@konzortia.capital. Attention: Legal Department.
            To request the termination of Services in accordance with
            this Agreement, You must submit a service request to
            InvestHub at the address specified in Your order or the
            Service Specifications.
          </p>
          <p className="  mb-5">
            ASSIGNMENT
          </p>
          <p className="  mb-5">
            Neither Party may assign this Agreement or give or transfer
            the Services or an interest in them to another individual or
            entity. If You grant a security interest in any portion of
            the Services, the secured party has no right to use or
            transfer the Services or any deliverables. The foregoing
            shall not be construed to limit the rights You may otherwise
            have with respect to Separately Licensed Third Party
            Technology licensed under open source or similar license
            terms.
          </p>
          <p className="  mb-5">
            OTHER
          </p>
          <p className="  mb-5">
            InvestHub is an independent contractor and Parties agree
            that no partnership, joint venture, agency, or employment
            relationship exists between them. Each Party is responsible
            for paying its own employees/contractors including
            employment related taxes and/or insurance. You shall defend
            and indemnify InvestHub against liability arising under any
            and all applicable laws, ordinances, or regulations related
            to Your termination or modification of the employment of any
            of Your employees in connection with any Services under this
            Agreement. You understand that InvestHub’s business
            partners, including any third party firms retained by You to
            provide consulting services or applications that interact
            with the Services, are independent of InvestHub and are not
            InvestHub’s agents. InvestHub is not liable for nor bound by
            any acts of any such business partner, unless the business
            partner is providing Services as an InvestHub subcontractor
            on an engagement ordered under this Agreement and, if so,
            then only to the same extent as InvestHub would be
            responsible for InvestHub resources under this Agreement.
          </p>
          <p className="  mb-5">
            If any term of this Agreement is found to be invalid or
            unenforceable, the remaining provisions will remain
            effective and such term shall be replaced with another term
            consistent with the purpose and intent of this Agreement.
            Except for actions for nonpayment or breach of InvestHub’s
            proprietary rights, no action, regardless of form, arising
            out of or relating to this Agreement may be brought by
            either party more than two years after the cause of action
            has accrued. You shall obtain at Your sole expense any
            rights and consents from third parties necessary for Your
            Content, Your Applications, and Third Party Content, as well
            as other vendor’s products provided by You that You use with
            the Services, including such rights and consents as
            necessary for InvestHub to perform the Services under this
            Agreement. You agree to provide InvestHub with all
            information, access, and full good faith cooperation
            reasonably necessary to enable InvestHub to provide the
            Services and You will perform the actions identified in Your
            order as Your responsibilities. You remain solely
            responsible for Your regulatory compliance in connection
            with Your use of the Services. You are responsible for
            making InvestHub aware of any and all technical requirements
            that result from Your regulatory obligations prior to
            entering into an order governed by this Agreement. InvestHub
            will cooperate with your efforts to determine whether use of
            the standard InvestHub Services offering is consistent with
            those requirements. Additional fees may apply to any
            additional work performed by InvestHub. InvestHub may audit
            Your use of the Services (e.g., through use of software
            tools) to assess whether Your use of the Services is in
            accordance with Your order. You agree to cooperate with
            InvestHub’s audit and provide reasonable assistance and
            access to information. Any such audit shall not unreasonably
            interfere with Your normal business operations. You agree to
            pay within 30 days of written notification any fees
            applicable to Your use of the Services in excess of Your
            rights. If You do not pay, InvestHub can end Your Services
            and/or Your order. You agree that InvestHub shall not be
            responsible for any of Your costs incurred in cooperating
            with the audit.
          </p>
          <p className="  mb-5">
            ENTIRE AGREEMENT
          </p>
          <p className="  mb-5">
            You agree that this Agreement and the information which is
            incorporated into this Agreement by written reference
            (including reference to information contained in a URL or
            referenced policy), together with the applicable order, is
            the complete agreement for the Services offered to You and
            supersedes all prior or contemporaneous agreements or
            representations, written or oral, regarding such Services.
            It is expressly agreed that the terms of this Agreement and
            any InvestHub order shall supersede the terms in any
            purchase order, procurement internet portal, or other
            similar non-InvestHub document and no terms included in any
            such purchase order, portal, or other non-InvestHub document
            shall apply to the Services ordered. This Agreement may not
            be modified, and the rights and restrictions may not be
            altered or waived except in a writing signed or accepted by
            both Parties.
          </p>
          <p className="  mb-5">
            No third party beneficiary relationships are created by this
            Agreement.
          </p>
          </article>
        <article className='flex justify-center items-center my-5'>
        <button onClick={exitModal} className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
          Close
        </button>
        <button onClick={exitModal} className='bg-orange font-noah-white active:bg-emerald-600  uppercase  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
           Okay
        </button>
        </article>
         
      </article>
        
      </section>
    </>
  )
  }else{
  return (
    <> 
    </>

  )
}}
