import React from 'react'

export default function SocialMedia() {
  return (
    <>
        <section className='konzortia-blue text-white center-div flex justify-center text-center flex-col py-10 text-xl'>
            <h3 className='font-bodonifl'>Follow Konzortia Capital’s Progress!</h3>
            <article className='text-white flex justify-center text-center flex-row gap-2 py-5'>
                <article>
                    <a href="https://www.facebook.com/konzortia/"><img className='w-10'src="Facebook-Icon.png" alt=""  /></a>
                </article>
                <article>
                    <a href="https://www.instagram.com/konzortia_capital/"><img className='w-10' src="instagram_icon.png" alt=""  /></a>
                </article>
                <article>
                    <a href="https://twitter.com/konzortia_c/"><img className='w-10' src="Twitter-icon.png" alt=""  /></a>
                </article>
                <article>
                    <a href="https://www.linkedin.com/company/konzortiacapital/mycompany/"><img className='w-10' src="linkedin-icon.png" alt=""  /></a>
                </article>
            </article>
        </section>
    </>
  )
}
