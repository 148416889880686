import React,{useState} from 'react'


export default function PitchDeckModal({modal, setModal}){
  const exitModal = ()=>{
    setModal("None")
  }

  if(modal === "PitchDeckModal"){
  return (
    <>
    <section className="konzortia-blue-modal flex justify-center flex-col md:flex-row items-center pb-5 z-30 fixed inset-0 outline-none focus:outline-none">
        <article className='bg-white h-50 px-2 md:px-10 py-10 z-50 border md:w-96 w-72'>
            <p className='text-red-600 text-sm flex justify-end cursor-pointer' onClick={exitModal}>X</p>
            <img className='w-40 mx-auto md:mb-6' src="Konzortia-Logo-transparent.png" alt="konzortia" />
            <h1 className='flex text-center md:mb-6 font-bold'>Thanks for requesting Konzortia's Capitial Pitch Deck</h1>
            <img className="mb-2" src="computer.png" alt="konzortia pitch deck img" />
            <p className='text-xs text-slate-400 pb-2'>if you have any questions and would like to discuss our Early Traction, Progress & Projections, or would like to know more about our Revenue Model.. please <a className="text-blue-500" href="https://calendly.com/rimas-siulys/">schedule a meeting</a> with our Director of Investor Relation</p>
            <a href="https://docsend.com/view/ae9ig3mj9dcrvn57"> <button className='text-white text-sm bg-blue-500 m-2 p-1 rounded mx-auto flex content-center justify-center'>REVIEW PITCH DECK</button></a>
            <article className=' flex flex-row mx-auto pt-2 content-center justify-center'>
              <a className='' href="https://www.linkedin.com/company/konzortiacapital/mycompany/"><img className='mr-2 w-5' src="linkedin-footer.png" alt="social"></img> </a>
              <a className='' href="https://twitter.com/konzortia_c/"><img className='mr-2 w-5' src="twitter-footer.png"  alt="social"></img> </a>
              <a className='' href="https://www.facebook.com/konzortia/"><img className='mr-2 w-5' src={require=('facebookfooter.png')}  alt="social f"></img> </a>
              <a className='' href="https://www.instagram.com/konzortia_capital/"><img className='mr-2 w-5' src={require=('instagramfooter.png')} alt="social I"></img> </a>
            </article>
        </article>
      </section>
    </>
  )
  }
}
